import _ from "lodash";
import { useEffect, useState } from "react";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { SubmitFunction } from "react-router-dom";

import {
  FileDownloader,
  StatusError,
} from "../../../activity/components/file-downloader";
import { apiUrl, User } from "../../../api";
import { UserForm } from "../user-form";
import { WorkspaceOption } from "../user-workspaces-field";
import { UsersList } from "../users-list";

interface ModalState {
  show: boolean;
}

interface UsersProps {
  users: User[];
  isSubmitting?: boolean;
  onSubmit: SubmitFunction;
  workspaceOptions: WorkspaceOption[];
}

export const Users = ({
  onSubmit,
  isSubmitting = false,
  users,
  workspaceOptions,
}: UsersProps) => {
  const [userModal, setUserModal] = useState<ModalState>({ show: false });
  useEffect(() => {
    if (!isSubmitting) {
      setUserModal({ show: false });
    }
  }, [isSubmitting]);
  if (users.length === 0) {
    return <Alert variant="info">No users</Alert>;
  }
  return (
    <>
      <div className="d-flex flex-wrap justify-content-start mb-3">
        <Stack className="mb-3 flex-wrap" direction="horizontal" gap={3}>
          <Button
            variant="primary"
            onClick={() => setUserModal({ show: true })}
          >
            Add user
          </Button>
          <input
            type="file"
            id="import-csv-file"
            className="visually-hidden"
            onChange={(e) => {
              if (_.isNull(e.target.files)) {
                return;
              }
              const formData = new FormData();
              formData.append("file", e.target.files[0]);
              onSubmit(formData, {
                method: "post",
                encType: "multipart/form-data",
              });
            }}
          />
          <Button
            as="label"
            role=""
            htmlFor="import-csv-file"
            variant="primary"
          >
            Import CSV
          </Button>
          <FileDownloader
            requestConfig={{ url: apiUrl("users/csv") }}
            name="users.csv"
          >
            {({ onClick, isLoading, error }) => {
              if (error instanceof StatusError && error.status === 403) {
                window.location.reload();
              } else if (error) {
                console.error(error);
              }
              return (
                <Button
                  variant={_.isUndefined(error) ? "primary" : "danger"}
                  onClick={onClick}
                  disabled={isLoading}
                >
                  {_.isUndefined(error) ? "Export CSV" : "Failed. Try again!"}
                </Button>
              );
            }}
          </FileDownloader>
        </Stack>
      </div>
      <UsersList users={users} />
      <UserForm
        isSubmitting={isSubmitting}
        onSubmit={(values) =>
          onSubmit(JSON.stringify(values), {
            method: "post",
            encType: "application/json",
          })
        }
        includeWorkspaces
        workspaceOptions={workspaceOptions}
      >
        {({ fields, submit }) => (
          <Modal
            size="xl"
            show={userModal.show}
            onHide={() => setUserModal({ show: false })}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add user</Modal.Title>
            </Modal.Header>
            <Modal.Body>{fields}</Modal.Body>
            <Modal.Footer>{submit}</Modal.Footer>
          </Modal>
        )}
      </UserForm>
    </>
  );
};
